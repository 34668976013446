<template>
  <div class="pageview">
    <!-- banner切换 -->
    <!-- <van-swipe class="homebanner"
      :autoplay="3000"
      indicator-color="white">
      <van-swipe-item><img style="height:17vh;"
          :src="require('../../assets/partymember/wuxing01.png')" /></van-swipe-item>
    </van-swipe> -->
    <div class="orderName">
      <div class="orderBox" @click="OrderShowClick">
        <img src="../../assets/address.png" style="width: 18px; height: 17px" />
        <span
          >当前社区:
          <span style="font-weight: 700">{{ OrderName }}</span>
        </span>
      </div>
      <div class="orderTitle">五星党建</div>
    </div>
    <!-- 社区列表弹框 -->
    <van-popup
      v-model="OrderShow"
      round
      position="bottom"
      get-container="body"
      :style="{ height: '40%' }"
    >
      <van-picker
        title="选择当前社区"
        show-toolbar
        :columns="OrderList"
        value-key="OName"
        @cancel="OrderShow = false"
        @confirm="OrderClick"
      >
      </van-picker>
    </van-popup>
    <van-swipe class="homebanner" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="banner in bannerImgs" :key="banner"
        ><img :src="banner" style="height: 172px; border-radius: 18px" />
      </van-swipe-item>
    </van-swipe>
    <!-- <div style="margin: 10px auto;border-radius: 5px;width: 95%;">
      <div style="border-radius: 8px;background: #ffff;"> -->
    <div
      style="
        border-radius: 8px;
        background: #ffff;
        padding: 3px;
        margin-top: 1.5vh;
      "
    >
      <van-grid
        class="wktype"
        :border="false"
        :column-num="5"
        style="margin-top: 0vh"
      >
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/zhibu')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/zhibu.png')"
          ></van-image>
          <span class="wktext">支部过硬星</span>
        </van-grid-item>
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/gongjian')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/gongjian.png')"
          ></van-image>
          <span class="wktext">共建共享星</span>
        </van-grid-item>
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/fazhi')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/fazhi.png')"
          ></van-image>
          <span class="wktext">平安法治星</span>
        </van-grid-item>
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/xingfu')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/xingfu.png')"
          ></van-image>
          <span class="wktext">幸福和谐星</span>
        </van-grid-item>
        <van-grid-item icon="photo-o" @click="gotoUrl('/fivestar/xingye')">
          <van-image
            class="iconimg"
            :src="require('../../assets/partymember/yiye.png')"
          ></van-image>
          <span class="wktext">宜业兴业星</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- </div>
    </div> -->
    <!-- <div style="margin: 10px auto;width: 100%;">
      <img :src="require('../../assets/shop/党员积分商城.png')"
        width="100%"
        alt=""
        @click="gotoUrl('/fivestar/integral')">

    </div> -->
    <div style="margin: 10px auto; width: 100%">
      <img
        :src="require('../../assets/shop/太康东路banner.png')"
        width="100%"
        style="border-radius: 8px"
        alt=""
      />
    </div>
    <div class="contentmsg">
      <div class="contentA">
        <img src="../../assets/五星.png" alt="" width="15px" />
        <!-- <img :src="require('../../assets/partymember/content.png')" /> -->
        <span>五星支部</span>
        <img
          class="moreImg"
          @click="gotoUrl('/partymember/consult')"
          :src="require('../../assets/partymember/more.png')"
        />
        <span class="moreCount" @click="gotoUrl('/partymember/consult')"
          >更多</span
        >
      </div>
      <div class="information">
        <div class="center" v-for="item in List" :key="item.index">
          <!-- <div @click="gotoUrl('/article/detail/' + item.RIId)"> -->
          <router-link :to="'/fivestar/detail/' + item.RIId">
            <div
              class="Content"
              style="border-bottom: 1px solid rgb(245 243 243)"
            >
              <div class="ContentB">
                <div class="centerTitle van-ellipsis" style="font-size: 16px">
                  {{ item.Title }}
                </div>
                <span
                  class="centertime van-multi-ellipsis--l2"
                  style="margin: 5px 0px; font-size: 13px"
                  >{{ item.Summary }}</span
                >
                <span class="centertime" style="color: #999999"
                  >{{ item.IssTime }} • {{ item.ReadNum }} 阅读</span
                >
              </div>
              <div
                style="display: inline-block; width: 33%"
                class="ContentA"
                ref="getHeight"
              >
                <img v-if="item.ThematicUrl" :src="item.ThematicUrl" alt="" />
              </div>
            </div>
          </router-link>
        </div>

        <van-empty description="暂无通知" v-if="!List.length" />
      </div>
    </div>
  </div>
</template>

<script>
import { WeGetPartyInfoPage } from "@/api/RealInfo";
import wx from "weixin-js-sdk";
import { WeGetOrganTree } from "@/api/oldman";
import {
  setOpenId,
  getOpenId,
  setOCode,
  getOCode,
  setOCodeName,
  getOCodeName,
} from "@/utils/auth";
import { WeGetAppletInfo } from "@/api/index";
export default {
  // computed: {
  //   ...mapGetters([
  //     'openId',
  //   ])
  // },
  components: {
    // Tabbar,
    // TipsMiniProject,
  },
  data() {
    return {
      List: [],
      bannerImgs: [],
      OrderShow: false, //社区列表显示
      OrderList: [], //社区列表
      OrderName: "光合城社区",
      OrderCode: "4103110201",
    };
  },
  created() {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      // this.$store.dispatch('user/setopenid', { openid: this.$route.query['openid'] })
      setOpenId(this.$route.query["openid"]);
    }
    if(getOCode()){
      this.OrderCode = getOCode()
    }else{
      setOCode(this.OrderCode);
    }
    if(getOCodeName()){
      this.OrderName = getOCodeName()
    }else{
      setOCodeName(this.OrderName);
    }
    this.getPartyInfoList();
    this.getAppInfo();
  },
  methods: {
    // 点击社区列表
    OrderShowClick() {
      WeGetOrganTree().then((res) => {
        this.OrderShow = true;
        this.OrderList = res.data.data[0].Children;
      });
    },
    // 选择社区
    OrderClick(row) {
      this.OrderShow = false;
      this.OrderName = row.OName;
      this.OrderCode = row.OCode;
      setOCode(this.OrderCode);
      setOCodeName(this.OrderName);
      this.getPartyInfoList();
    },
    // 小程序展示数据
    getAppInfo() {
      WeGetAppletInfo().then((res) => {
        var list = res.data;
        if (list.code == 0) {
          var imgs = [];
          imgs[0] = list.data.SysPic1Url;
          imgs[1] = list.data.SysPic2Url;
          imgs[2] = list.data.SysPic3Url;
          this.bannerImgs = imgs;
          this.appInfo = list.data;
        }
      });
    },
    //跳转到公共页面
    gotoUrl(path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
    // 通知消息
    getPartyInfoList() {
      WeGetPartyInfoPage({
        pAKind: 2,
        page: 1,
        limit: 5,
        orgCode: getOCode(),
      }).then((res) => {
        this.List = res.data.data;
        for (var i = 0; i < this.List.length; i++) {
          var item = this.List[i].IssTime;
          item = this.dataFormat(item);
          this.List[i].IssTime = item;
          if (this.List[i].ThematicUrl == "") {
            this.List[i].ThematicUrl =
              "http://lccgzapi.zhsq.cloud//upload/images/2021/11/b6b3ab86-df3.png";
          }
        }
        // 测试数据
        // this.List = [
        //   { Title: "书记茶话会", IssTime: "一杯清茶话家常，在聊天中了解居民群众的急难愁盼问题。今年以来，创新党建引领基层治理新模式，在全市首创“社区书记茶话会”社区共建共治共享工作品牌，由社区党委书记牵头，每月邀请区、街道相关负责人和社区各界群众代表，以喝茶聊天的方式，倾听解决群众操心事、烦心事、揪心事，获得了辖区居民的认可和称赞。", ThematicUrl: "http://lccgzapi.zhsq.cloud//upload/images/2021/11/b6b3ab86-df3.png" },
        // ]
        console.log(this.List);
      });
    },
    // 党务
    dangwu: function () {
      var url =
        "https://szhb.hebi.gov.cn/newsList?path=dangjianyaowen&title=%E5%85%9A%E5%BB%BA%E8%A6%81%E9%97%BB";
      window.localtion.href = url;
      // window.open('https://szhb.hebi.gov.cn/newsList?path=dangjianyaowen&title=%E5%85%9A%E5%BB%BA%E8%A6%81%E9%97%BB');
    },
    // 时间格式化
    dataFormat(value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
};
</script>

<style scoped>
.ContentB {
  display: inline-block;
  width: 50% !important;
  margin-right: 6%;
  color: #666666 !important;
  margin-left: 5% !important;
}
.djlink {
  margin-top: 1.5vh;
  border-radius: 10px;
  overflow: hidden;
  display: block;
  font-size: 0;
}
.contentA {
  width: 100%;
  overflow: hidden;
  line-height: 30px;
  padding: 2vh 1.5vh 0vh 1.5vh;
  box-sizing: border-box;
}
.contentA img {
  float: left;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-top: 7px;
}
.contentA span {
  float: left;
  font-size: 18px;
  color: #333333;
  font-weight: 700;
  line-height: 30px;
}
.contentA .newsmore {
  float: right;
  color: #777;
  font-size: 14px;
  line-height: 30px;
}
.contentA .blue {
  color: #477fff;
}
.pageview {
  background: #f6f6f6;
  min-height: 100%;
  padding: 0vh 1vh 1vh;
  box-sizing: border-box;
}
.homebanner img {
  width: 100%;
  height: 27vh;
  vertical-align: top;
}
.wktype {
  padding: 0;
  margin-top: 1.5vh;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 8px;
  font-size: 12px;
}
.contentmsg {
  background: #fff;
  margin-top: 1.5vh;
  border-radius: 8px;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 5px 10px 0px;
}
/deep/.wktype .van-grid-item__content {
  padding: 20px 3px;
}
.moreCount {
  color: #999999 !important;
  float: right !important;
  margin-right: 7px !important;
  font-size: 14px !important;
  font-style: initial !important;
}
.moreImg {
  float: right !important;
  width: 8px !important;
  height: 12px !important;
  margin-top: 9px !important;
}
.orderName {
  color: #fff;
  font-size: 14px;
  margin: 3px 0px 10px;
  display: flex;
  align-items: center;
}
.orderBox {
  display: flex;
  align-items: center;
  background: #fd8f8f;
  width: 65%;
  height: 30px;
  padding-left: 7px;
  border-radius: 5px;
  border-bottom-right-radius: 15px;
}
.orderTitle {
  background: #fd8f8f;
  margin-left: 5px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 32%;
  border-radius: 10px;
  font-weight: 700;
}
</style>